/* components/navbar.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01m,09dec24,mno  renamed 'Sign up' to 'Host on Tikkl'
01l,26apr24,mno  remove menu: Signup, renamed menu items: Find Events, (org) Sign up.
01k,22aug23,mno  pruned sub-menu "host your event"; added link why-tikkl.
01j,31jul23,mno  added nav to /festivals
01i,13jan23,mno  Organization spl > Org.
01h,14oct22,mno  updated styles.
01g,30aug22,mno  named exports.
01f,20jul22,mno  updated navbar links; show-hide navbar.
01e,08dec21,mno  added signup link.
01d,01dec21,mno  reordered personal menu.
01c,29nov21,mno  renamed menu: 'Host an event'
01b,16nov21,mno  updated user-menu.
01a,09oct20,mno  created.
*/

import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import Notifications from 'react-notify-toast';
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from 'gatsby-image';
import { debounce } from 'lodash';
import ButtonLink from './button_link';
import isPresent from "../lib/is_present";
import tikklUrl from '../lib/tikkl_url';

const Navbar = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "tikkl-logo.png" }){
        childImageSharp {
          fixed(height: 28) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }`
  );

  const [ state, updateState ] = useState({ isBurgerActive: false, isNavHidden: false, pageYOffset: -10, animateNav: false });
  const { userName } = props;

  // console.log('re-render: state=', state);

  const handleScroll = debounce(
    () => updateState(s => {
      const pageYOffset = window.pageYOffset;

      //console.log('state: ', s);

      // ignore calls until the page settles.
      if (s.pageYOffset < 40){
        return {...s, pageYOffset: s.pageYOffset + 1 };
      }

      if (pageYOffset < s.pageYOffset && s.isNavHidden){
        // console.log('showing...');
        return {...s, isNavHidden: false, pageYOffset, animateNav: true };
      }

      if (pageYOffset > s.pageYOffset && !s.isNavHidden){
        // console.log('hiding...');
        return { ...s, isNavHidden: !s.isBurgerActive, pageYOffset, animateNav: true };
      }

      return {...s, pageYOffset };
    }),
    150, {  // wait (debounce period)
      leading:  true, // trigger on leading edge.
      trailing: false,
    }
  );

  const handleMenuTouch = debounce(
    () => updateState(s => ({...s, isNavHidden: false })),
    0, {
      leading: true,
      trailing: false,
    }
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return (() => window.removeEventListener('scroll', handleScroll));
  }, [handleScroll]);

  return (
    <>
      {/* container for react-notify-toast */}
      <Notifications options={{ zIndex: 100, top: '20px' }} />
      <div
        className="tikkl-navbar-control"
        style={{height: '1rem', width: '100%', background: 'transparent', position: 'fixed', top: 0, left: 0, zIndex: 10 }}
        role="presentation"
        aria-label="Menu Show"
        onMouseEnter={() => handleMenuTouch()}
        onTouchStart={() => handleMenuTouch()}
      />
      <nav
        id="tikkl-navbar"
        className={classNames("navbar is-spaced animate__animated", {
          'animate__slideOutUp':  state.animateNav && state.isNavHidden,
          'animate__slideInDown': state.animateNav && !state.isNavHidden,
        })}
        role="navigation"
        aria-label="main navigation"
        style={{ zIndex: 20 }}
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <Img fixed={data.file.childImageSharp.fixed} alt="Tikkl Logo" />
            </Link>

            <a
              href="#"
              role="button"
              className={classNames("navbar-burger burger", { 'is-active': state.isBurgerActive })}
              aria-label="menu"
              aria-expanded="false"
              data-target="burger-menu"
              onClick={() => updateState({ ...state, isBurgerActive: !state.isBurgerActive })}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div
            id="tikkl-navbar-menu"
            className={classNames('navbar-menu', { 'is-active': state.isBurgerActive })}
          >
            <div className="navbar-end">
              <a href={tikklUrl('/featured-events')} className="navbar-item">Find Events</a>
              <div className="navbar-item has-dropdown is-hoverable">
                <a href="#" className="navbar-link is-dropdown-title"> Host your Event </a>
                <div className="navbar-dropdown" style={{ left: '-2rem' }}>
                  <Link to="/whytikkl" className="navbar-item">Why Host on Tikkl</Link>
                  <Link to="/features" className="navbar-item">Features</Link>
                  <Link to="/pricing" className="navbar-item">Pricing</Link>
                  <span className="navbar-divider"/>
                  <Link to="/signup" className="navbar-item is-primary">Host on Tikkl</Link>
                </div>
              </div>
              {
                !isPresent(userName)
                  && <ButtonLink buttonText="Login" href={tikklUrl('/login')} isPrimary isOutlined isRounded/>
              }
              {
                isPresent(userName) && (
                  <div className="navbar-item has-dropdown is-hoverable">
                    <a href="#" className="navbar-link is-dropdown-title">{userName}</a>
                    <div className="navbar-dropdown">
                      <a href={tikklUrl("/me")} className="navbar-item">My Home</a>
                      <a href={tikklUrl("/a/me/tickets")} className="navbar-item">My Tickets</a>
                      <a href={tikklUrl("/a/me/transactions")} className="navbar-item">Transactions</a>
                      <a href={tikklUrl("/a/me/profile")} className="navbar-item">Profile</a>
                      <a href={tikklUrl("/a/me/security")} className="navbar-item">Security</a>
                      <span className="navbar-divider"/>
                      <a href={tikklUrl("/logout?force=true")} className="navbar-item is-primary">Logout</a>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
